export const loadCheckout = (callback: any) => {
	const existingScript = document.getElementById('checkout');
	if (!existingScript) {
		const script = document.createElement('script');
		script.src = `${process.env.PUBLIC_URL}/checkout2.js`;
		script.id = 'checkout';
		document.body.appendChild(script);
		script.onload = () => {
			if (callback) callback();
		};
	}
	if (existingScript && callback) callback();
};

export const loadMercadoPago = (callback: any) => {
	const existingScript = document.getElementById('mercadoPago');
	if (!existingScript) {
		const script = document.createElement('script');
		script.src = 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
		script.id = 'mercadoPago';
		document.body.appendChild(script);
		script.onload = () => {
			if (callback) callback();
		};
	}
	if (existingScript && callback) callback();
};

export const loadTickets = () => {
	const existingScript = document.getElementById('tickets-component');
	if (!existingScript) {
		const script = document.createElement('script');
		script.src = `${process.env.PUBLIC_URL}/tickets.js`;
		script.id = 'tickets-component';
		document.body.appendChild(script);
	}
};
